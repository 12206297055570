import React from 'react'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import {graphql} from "gatsby";
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => <p className="align-center">{children}</p>

const options = {
    renderMark: {
        [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    },
}

class PageTemplate extends React.Component {
    render() {
        const content = get(this.props, 'data.contentfulPage')
        const siteTitle = get(this.props, 'data.site.siteMetadata.title')
        
        return(
            <div>
                <Helmet title={`${content.title} | ${siteTitle}`} />
                <div className="wrapper">
                    <h1 className="section-headline">{content.title}</h1>
                    <div>
                        {documentToReactComponents(content.body.json, options)}
                    </div>
                </div>
            </div>);
        
    }
}

export default PageTemplate;

export const pageQuery = graphql`
    query PageBySlug($slug: String!) {
        contentfulPage(slug: {eq: $slug}) {
            title
            body {
              json
            }
        }
    }
`
